import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../common/Spinner';
import { AddWalkInDetails } from './AddWalkInDetails';
import { resetWalkInState } from '../../../../slices/walkInDriveSlice';

const ScheduleWalkIn = () => {

    const dispatch = useDispatch();
    const {editWalkIn} = useSelector(state => state.walkInDrive);

    useEffect(()=>{
        dispatch(resetWalkInState());
    },[])

    if(editWalkIn)
        return <Spinner/>

  return (
    <>
        <AddWalkInDetails/>
    </>
  )
}

export default ScheduleWalkIn