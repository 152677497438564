import React from 'react'
import { formattedDate } from '../../../../utils/dateFormatter'

const Qualifications = ({education}) => {
  return (
    <div className='flex flex-col  text-[15px]'>
        {/* <label className='flex flex-col gap-2 xxxs:w-[30%]'>
            <p className='text-sm text-richblack-600'>First Name</p>
            
            </label> */}

        <p className='text-[17px] font-bold'>{`${education?.course}, ${education?.specialization}`}</p>
        <p className='text-richblack-50'>{education?.institute}</p>
        <p className='text-md text-richblack-25'>
            {`${formattedDate(education?.startDate)} - ${formattedDate(education?.endDate)}`}
        </p>
    </div>
   
  )
}

export default Qualifications