import React, { useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchWalkInDriveDetails } from '../../../services/operations/JobAPI';
import { formattedFullDate, toDays } from '../../../utils/dateFormatter';
import Spinner from '../../common/Spinner';
import IconBtn from '../../common/IconBtn';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useSelector } from 'react-redux';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import toast from 'react-hot-toast';

const WalkInDetails = () => {

  const navigate = useNavigate();
  const {walkInId} = useParams();
  const {token} = useSelector(state => state.auth);
  const {user} = useSelector(state => state.profile);

  const [wd, setWd] = useState({});
  const [loading,setLoading] = useState(false);
  const [confirmationModal,setConfirmationModal] = useState(null);

  const setWalkInData = async(walkInId)=>{
      setLoading(true);
      const result = await fetchWalkInDriveDetails(walkInId);
      if(result){
          setWd(result);
      } 
      setLoading(false);
  }
  // console.log("result: ",course);

  useLayoutEffect(()=>{

      setWalkInData(walkInId);

  },[]);
  // console.log(wd)

  
  const viewRecruiter = (id)=>{

    //not logged in user
    if(!token){
        setConfirmationModal({
            text1:"You are not logged in!",
            text2:"Please login to View.",
            btnText1:"Login",
            btnText2:"Cancel",
            btnHandler1:()=>{navigate('/login')},
            btnHandler2:()=>{setConfirmationModal(null)}
        });
        return;
    }
    
    //If Other Recruiter 
    if(user?.accountType === ACCOUNT_TYPE.RECRUITER){
        toast.error("User must not be a Recruiter");
        return;
    }
    
    navigate(`/dashboard/recruiter/${id}`);
  }

  if(loading)
    return <Spinner/>

if(loading && !wd)
    return <Spinner/>

  console.log(wd)
  return (
    wd &&
    <div className='w-full h-full'>
      <p className='text-2xl font-bold py-3 w-full border-b mb-4 flex justify-between'>
        <span>Check details</span>
        <IconBtn
          type={'button'}
          onclick={()=>viewRecruiter(WalkInDetails?.company?._id)}
          text={"View Recruiter"}
          customClasses={'text-sm'}
        />
      </p>
      <p 
        className='text-xl font-bold flex items-center gap-3 flex-wrap text-yellow-50'
      >
        Roles - 
        {
          wd && wd?.roles?.map((role,id)=>(
            <span key={id}>{role}</span>
          ))
        }
      </p>
      <p>
        <span className='font-bold'>Category - </span>
        <span>{wd?.category?.name}</span>
      </p>
      <p>
        <span className='font-bold'>Location - </span>
        <span>{wd?.location}</span>
      </p>
      <p>
        <span className='font-bold'>Scheduled On - </span>
        <span>{formattedFullDate(wd?.scheduleDay)}</span>
      </p>
      <p>
        <span className='font-bold'>Last Date to Register - </span>
        <span>{formattedFullDate(wd?.deadline)}</span>
      </p>
      <p className='text-sm text-yellow-50'>
        Posted on {formattedFullDate(wd?.createdAt)}
      </p>

      <div className='flex py-4 gap-4 border-b mb-8 mt-2'>
        <a href={wd?.details} target='_blank'>
          <IconBtn outline={true}>
            View Drive Details
          </IconBtn>
        </a>
        {/* <IconBtn>Register Now</IconBtn> */}
      </div>
      {   
        confirmationModal &&
        <ConfirmationModal
            modalData={confirmationModal}
        />
      }
    </div>
  )
}

export default WalkInDetails