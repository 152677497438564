import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createWalkInDrive, editWalkInDriveDetails, fetchCategories } from '../../../../services/operations/JobAPI';
import ChipInput from '../../../common/ChipInput';
import toast from 'react-hot-toast';
import IconBtn from '../../../common/IconBtn';
import { MdOutlineNavigateNext } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { resetWalkInState, setWalkInLoading } from '../../../../slices/walkInDriveSlice';

export const AddWalkInDetails = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {token} = useSelector(state => state.auth);
  const {user} = useSelector(state => state.profile);
  const {walkIn, editWalkIn, walkInLoading} = useSelector(state => state.walkInDrive)
  const [categories , setCategories] = useState([]);
  const [category,setCategory] = useState("");

  const {

    setValue,
    getValues,
    register,
    handleSubmit,
    reset,
    formState : {errors}

  } = useForm();

  const getCategories = async()=>{
    dispatch(fetchCategories(setCategories));
  }

  useEffect(()=>{
    getCategories();
    
    if(editWalkIn){
      // console.log("edit: ",job);
      setValue("roles",walkIn?.roles);
      setValue("category",walkIn?.category?._id);
      setValue("location",walkIn?.location);
      setValue("deadline",walkIn?.deadline);
      setValue("scheduleDay",walkIn?.scheduleDay);
      setValue("details",walkIn?.details);
      setCategory(walkIn?.category?._id);
    }
    
    register("details",{
        required:{
            value:true,
            message:"Required"
        }
    });

  },[]);

  const isFormUpdated = ()=>{
    const currentValues = getValues();
    if(
      currentValues.category !== walkIn?.category?._id ||
      currentValues.roles.toString() !== walkIn?.roles.toString() ||
      currentValues.location !== walkIn?.location ||
      currentValues.deadline !== walkIn?.deadline ||
      currentValues.scheduleDay !== walkIn?.scheduleDay ||
      currentValues.details !== walkIn?.details
    )
      return true;
    else 
      return false;
  }

  const submitHandler = async(data)=>{
    dispatch(setWalkInLoading(true));
    // console.log(data)

    if(user && !user?.address || !user?.poc || !user?.pocDesignation){
      dispatch(setWalkInLoading(false));
        toast.error('User profile not updated');
        return;
    }

    if(editWalkIn){
      if(isFormUpdated()){
        
        const currentValues = getValues();
        // console.log("data",currentValues)
    
        const formData = new FormData(); 
        formData.append("walkInId",walkIn?._id); 

        if( currentValues?.roles !== walkIn?.roles?.toString()) 
          {
            data = {...data, roles: JSON.stringify(data?.roles)}; 
            formData.append("roles",data?.roles);
          }
        
        if(currentValues?.category !== walkIn?.category?._id) 
          formData.append("category",data?.category?._id);
        
        if(currentValues?.location !== walkIn?.location) 
          formData.append("location",data?.location);
        
        if(currentValues?.deadline !== walkIn?.deadline) 
          formData.append("deadline",data?.deadline);
        
        if(currentValues?.scheduleDay !== walkIn?.scheduleDay) 
          formData.append("scheduleDay",data?.scheduleDay);
        
        if(currentValues?.details !== walkIn?.details) 
          formData.append("details",data?.details);

        //Save the changes in backend 
        const result = await editWalkInDriveDetails(formData,token);

        if(result){
          dispatch(resetWalkInState());
          navigate('/dashboard/recruiter/my-walk-in-drives');
        }

      }
      else{
        toast.error("No Changes made so far");
      }
      dispatch(setWalkInLoading(false));
      return;
    }

    //Create Drive
    data = {
      ...data,
      roles: JSON.stringify(data?.roles),
      // details: data?.details[0]
    }
    // console.log(data)
    
    const formData = new FormData();
    formData.append("roles",JSON.stringify(data.roles));
    formData.append("category",data?.category?._id);
    formData.append("location",data?.location);
    formData.append("scheduleDay",data?.scheduleDay);
    formData.append("deadline",data?.deadline);
    // formData.append("details",data?.details);

    const result = await createWalkInDrive(data,token,navigate);

    // if(result){
    //   navigate('/dashboard/recruiter/my-walk-in-drives');
    // }
    dispatch(setWalkInLoading(false));
  }

  return (
    <div className='w-11/12 h-auto sm:px-6 mx-auto text-richblack-5 font-inter flex flex-col items-center'>
      <h1 className='p-2 text-4xl font-bold mb-8'>{!editWalkIn ? "Schedule Walk In Drive" : "Edit Walk In Drive"}</h1>
      <form
        className='w-full max-w-[500px] flex flex-col justify-around'
        onSubmit={handleSubmit(submitHandler)}>
      
        <div
          className='flex flex-col gap-6 bg-richblack-800 p-6 max-sm:px-3 rounded border border-richblack-700'>
                   
          {/* Job Roles */}
          <ChipInput
            label={<p>Job Roles <sup>*</sup></p>}
            edit={editWalkIn}
            editData={walkIn?.roles ? walkIn?.roles : []}
            name={"roles"}
            type={"text"}
            placeholder={"Enter Role and press Enter"}
            register={register}
            errors={errors}
            setValue={setValue}
            require={"Roles"}
          />
      
          <div className='w-full flex gap-3 items-end'>
                      
            {/* Category */}
            <div className='flex flex-col w-full'>
                <label htmlFor="category">
                    <p className='text-[16px]'>Roles Category <sup>*</sup> </p>
                    <select
                        {...register("category",{
                        required:{
                            value:true,
                            message:"Job category is required"
                        }
                        })}
                        value={category}
                        onChange={(e)=>{setCategory(e.target.value)}}
                        id="category"
                        className="outline-none cursor-pointer mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]"
                    >
                        <option value="" disabled>Choose a Category</option>
                        {!walkInLoading &&
                            categories.map(ct=>{
                                return <option key={ct?._id} value={ct?._id}>
                                        {ct?.name}
                                    </option>
                            })
                        }
                    </select>
                    {errors.category && (
                        <span className="mt-1 text-pink-200 text-[12px]">
                            {errors.category.message}
                        </span>
                    )}
                </label>
            </div>
            {/* location */}
            <div className='flex flex-col flex-grow'>
              <label htmlFor="location">
                  <p className='text-[16px]'>Location<sup>*</sup> </p>
                  <input
                    className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                    name='role'
                    placeholder='Enter Drive Location'
                    type='text'
                    {...register("location",{
                        required:{
                        value:true,
                        message:"Location is required"
                        }
                        })}
                    />
                    {
                    errors.location &&
                    <span className='mt-1 text-pink-200 text-[12px]'>
                        {errors.location.message}
                    </span>
                    }
              </label>
            </div>

          </div>
      
          <div className='w-full flex gap-3 items-end'>
            
            {/* Day of Drive*/}
            <div className='flex flex-col flex-grow'>
                <label htmlFor="scheduleDay">
                    <p className='text-[16px]'>Scheduled Day<sup>*</sup> </p>
                    <input
                        className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                        name='scheduleDay'
                        placeholder='Enter Drive Scheduled Date'
                        type='text'
                        {...register("scheduleDay",{
                            required:{
                                value:true,
                                message:"Required"
                            }
                        })}
                    />
                    {
                        errors.scheduleDay &&
                        <span className='mt-1 text-pink-200 text-[12px]'>
                            {errors.scheduleDay.message}
                        </span>
                    }
                </label>
            </div>

            {/* Last Date of Registration*/}
            <div className='flex flex-col flex-grow'>
                <label htmlFor="deadline">
                    <p className='text-[16px]'>Last Date of Registration<sup>*</sup> </p>
                    <input
                        className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                        name='deadline'
                        placeholder='Enter Last Date of Registration'
                        type='text'
                        {...register("deadline",{
                            required:{
                                value:true,
                                message:"Required"
                            }
                        })}
                    />
                    {
                        errors.deadline &&
                        <span className='mt-1 text-pink-200 text-[12px]'>
                            {errors.deadline.message}
                        </span>
                    }
                </label>
            </div>

          </div>
        
          <div className='w-full flex gap-3 items-end'>
            {/* Drive Details File*/}
            <div className='flex flex-col flex-grow'>
                <label htmlFor="details">
                    <p className='text-[16px]'>Drive Details File<sup>*</sup> </p>
                    <input
                        className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                        name='details'
                        placeholder='Upload Drive Details File'
                        type='file'
                        onInput={(e)=>{
                          setValue("details",e.target.files[0])
                        }}
                    />
                    {
                        errors.details &&
                        <span className='mt-1 text-pink-200 text-[12px]'>
                            {errors.details.message}
                        </span>
                    }
                    {
                      walkIn?.details &&
                      <div className='flex gap-2 items-center p-2 text-yellow-50'>
                        <span className='font-bold text-base'>Current File: </span>
                        <a className='font-medium' href={walkIn?.details} target='_blank' >Link</a>
                      </div>
                    }
                </label>
            </div>

        </div>
            <div className='flex flex-wrap-reverse self-end gap-5'>
                <IconBtn
                text={!editWalkIn ? "Create" : "Save Changes"}
                disabled={walkInLoading}
                >
                    <MdOutlineNavigateNext />
                </IconBtn>
            </div>
        </div>
      </form>
    </div>
  )
}
