import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateProfile } from '../../../../services/operations/SettingsAPI';
import { ACCOUNT_TYPE } from '../../../../utils/constants';
import { isValidURL, validateDOB } from '../../../../utils/dobValidation';
import toast from 'react-hot-toast';
import EducationForm from './EducationForm';
import CertificationForm from './CertificationForm';
import ChipInput from '../../../common/ChipInput';
import Spinner from '../../../common/Spinner';

const ApplicantProfileForm = () => {

    const dispatch = useDispatch();
    const {user} = useSelector(state =>  state.profile);
    const {token} = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);

    const genderData = [
        "Male",
        "Female",
        // "Binary",
        // "Non Binary",
        // "Prefer Not to Say"
    ];

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors,isSubmitSuccessful}
    
    } = useForm();

    useEffect(()=>{
        if(isSubmitSuccessful || user){
            reset({
                firstName:user?.firstName,
                lastName:user?.lastName,
                dob:user?.dob,
                gender:user?.gender,
                mobile:user?.mobile,
                description:user?.description,
                resume:user?.resume,
                linkedIn:user?.linkedIn,
                portfolio:user?.portfolio,
                skills:user?.skills
            });
            // console.log("reseting...")
        }
    },[reset,isSubmitSuccessful,user]);

    const submitHandler = async(data)=>{
        try{
            setLoading(true);
            // console.log("data",data);
            
            if(!validateDOB(data?.dob)){
                toast.error("Age must be 18 years or above");
                throw new Error("Age must be 18 years or above");
            }

            if(!isValidURL(data?.resume)){
                toast.error("Not a valid URL for Resume");
                throw new Error("Not a valid URL for Resume");
            }

            if(!isValidURL(data?.linkedIn)){
                toast.error("Not a valid URL for LinkedIn");
                throw new Error("Not a valid URL for LinkedIn");
            }

            if(data?.portfolio && !isValidURL(data?.portfolio)){
                toast.error("Not a valid URL for Portfolio");
                throw new Error("Not a valid URL for Portfolio");
            }

            data = {
                ...data,
                skills: JSON.stringify(data?.skills)
            }
            
            dispatch(updateProfile(token,data,user?.accountType)).then(()=>{
            setLoading(false);
                // console.log("user data",user);
            });
        }catch(err){
            console.log("ERROR MESSAGE - ", err.message)
            setLoading(false);
        }
    }

    if(loading) return <Spinner/>

  return (
    <section>
        {/* Personal details Form */}
        <form
            className='flex flex-col'
            onSubmit={handleSubmit(submitHandler)}>
        
            <div className='my-6 flex flex-col justify-between gap-6 bg-richblack-800 p-6 sm:px-10 px-6  rounded-md border border-richblack-700'>

                <p className='text-xl font-bold'>Profile Information</p>

                <div className='flex max-sm:flex-col gap-6'>
                    {/* First Name */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>First Name <sup>*</sup></p>
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                            type='text'
                            name='firstname'
                            placeholder='Enter First Name'
                            {...register("firstName",{
                                required:{
                                    value:true,
                                    message:"Please enter first name"
                                }
                            })}
                            defaultValue={user?.firstName}
                        />
                        {
                            errors.firstName &&
                            <span className="text-[12px] text-yellow-100">
                                Please Enter First Name
                            </span>
                        }
                    </label>
                    {/* Last Name  */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>Last Name <sup>*</sup></p>
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                            type='text'
                            name='lastname'
                            placeholder='Enter Last Name'
                            {...register("lastName",{
                                required:{
                                    value:true,
                                    message:"Please enter last name"
                                }
                            })}
                            defaultValue={user?.lastName}
                        />
                    </label>
                </div>

                <div className='flex max-sm:flex-col gap-6'>
                    {/* dob */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>Date Of Birth <sup>*</sup></p>
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'

                            type='date'
                            name='dob'
                            {...register("dob", {
                                required:{
                                    value:true,
                                    message:"Please enter date of birth"
                                },
                                validate: (val)=> {
                                    if(!validateDOB(val)) 
                                        return "Age must be 18 years or above"; 
                                    return true;
                                },
                                max: {
                                    value: new Date().toISOString().split("T")[0],
                                    message: "Date of Birth cannot be in the future.",
                                },
                            })}
                            defaultValue={user?.dob}
                        />
                        {errors.dob && (
                            <span className="text-[12px] text-yellow-100">
                                {errors.dob.message}
                            </span>
                        )}
                    </label>
                    {/* gender */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>Gender <sup>*</sup></p>
                            <select
                                
                                className="outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]"

                                name="gender"
                                size={1}
                                {...register("gender",)}
                                defaultValue={user?.gender}>

                                {
                                    genderData.map((element,index)=>{
                                        return <option key={index} value={element}>
                                                {element}
                                            </option>
                                    })
                                }
                            </select>
                            {/* {errors.gender && (
                                <span className="text-[12px] text-yellow-100">
                                Please enter your Date of Birth.
                                </span>
                            )} */}
                    </label>
                </div>

                    {/* Description */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>Summary <sup>*</sup></p>
                        <textarea
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                            rows={3}
                            name='description'
                            placeholder='Enter something about yourself'
                            {...register("description",{
                                required:{
                                    value:true,
                                    message:"Please enter a short description"
                                },
                                minLength:{
                                    value:300,
                                    message:"Description should be of minimum 300 letters"
                                },
                                maxLength:{
                                    value:600,
                                    message:"Description should not exceed 600 letters"
                                }
                            })}
                            defaultValue={user?.description}
                        />
                        {
                            errors.description &&
                            <span className=" text-[12px] text-yellow-100">
                                {errors.description?.message}
                            </span>
                        }
                    </label>

                <div className='flex max-sm:flex-col gap-6'>
                    {/* Contact Number */}
                    <label className='flex flex-col'>
                        <p className='text-[16px]'>Contact Number <sup>*</sup></p>  
                        
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'

                            type='tel'
                            name='mobile'
                            placeholder='Enter Contact Number'
                            {...register("mobile",
                            {
                                required:{value:true,message:"Please Enter Your Contact Number"},
                                maxLength:{value:10,message:"Invalid Contact Number"},
                                minLength:{value:8,message:"Invalid Contact Number"}
                            })}
                            defaultValue={user?.mobile}
                        />
                        {
                            errors.mobile &&
                            <span className="text-[12px] text-yellow-100">
                                {errors.mobile.message}
                            </span>
                        }
                    </label>
                    
                    {/* Resume */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>Resume Link <sup>*</sup></p>
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                            type='text'
                            name='resume'
                            placeholder='Enter Resume Link'
                            {...register("resume",{
                                required:{
                                    value:true,
                                    message:"Please enter a link to your resume"
                                }
                            })}
                            defaultValue={user?.resume || ""}
                        />
                        {
                            errors.resume &&
                            <span className=" text-[12px] text-yellow-100">
                                {errors.resume?.message}
                            </span>
                        }
                    </label>
                </div>
                
                <div className='flex max-sm:flex-col gap-6'>
                    {/* LinkedIn */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>LinkedIn <sup>*</sup></p>
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                            type='text'
                            name='linkedIn'
                            placeholder='Enter LinkedIn Profile Link'
                            {...register("linkedIn",{
                                required:{
                                    value:true,
                                    message:"Please enter a link to your LinkedIn Profile"
                                }
                            })}
                            defaultValue={user?.linkedIn || ""}
                        />
                        {
                            errors.linkedIn &&
                            <span className=" text-[12px] text-yellow-100">
                                {errors.linkedIn?.message}
                            </span>
                        }
                    </label>
                    {/* Portfolio */}
                    <label className='flex flex-col w-full'>
                        <p className='text-[16px]'>Portfolio/Github Link</p>
                        <input
                            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
                            type='text'
                            name='portfolio'
                            placeholder='Enter Portfolio Link'
                            {...register("portfolio")}
                            defaultValue={user?.portfolio || ""}
                        />
                    </label>
                </div>
                {/* Skills */}
                <ChipInput
                    label={<p>Skills <sup>*</sup></p>}
                    edit={user ? true : false}
                    editData={user?.skills ? user?.skills : []}
                    name={"skills"}
                    type={"text"}
                    placeholder={"Enter Skill and press Enter"}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    require={"Skills"}
                    isSubmitSuccessful={isSubmitSuccessful}
                />
            </div>
            
            {/* Form Buttons */}
            <div className='flex items-center gap-2 self-end'>
                {/* Cancel */}
                <Link to={`/dashboard/${
                    user?.accountType === ACCOUNT_TYPE.APPLICANT ? "applicant" 
                    : (user?.accountType === ACCOUNT_TYPE.RECRUITER ? "recruiter" : "admin") 
                }/profile`}>
                    <button 
                        type='reset'
                        className='p-2 px-5 bg-richblack-700 text-richblack-5 font-bold rounded-md'
                        disabled={loading}>
                        Cancel
                    </button>
                </Link>
                {/* Submit */}
                <button 
                    className='p-2 px-5 bg-yellow-50 text-richblack-900 font-bold rounded-md'
                    type='save'
                    disabled={loading}>

                    Save
                </button>
            </div>
        </form>
    
        {/* Education Details Form */}
        <div className='my-6 flex flex-col justify-between gap-6 bg-richblack-800 p-6 sm:px-10 px-6  rounded-md border border-richblack-700'>
            <p className='text-xl font-bold'>Education Qualification</p>
            {
                user?.education && user?.education?.map((ed)=>(
                    <EducationForm key={ed?._id} data={ed}/>
                ))
            }
            <EducationForm/>
        </div>

        {/* Certification Details Form */}
        <div className='my-6 flex flex-col justify-between gap-6 bg-richblack-800 p-6 sm:px-10 px-6  rounded-md border border-richblack-700'>
            <p className='text-xl font-bold'>Certifications</p>
            {
                user?.certification && user?.certification?.map((ct)=>(
                    <CertificationForm key={ct?._id} data={ct}/>
                ))
            }
            <CertificationForm/>
        </div>
    </section>
  )
}

export default ApplicantProfileForm;