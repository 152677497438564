import toast from "react-hot-toast";
import { apiConnector } from '../apiconnector';
import { settingsEndpoints } from '../apis';
import { setUser } from "../../slices/profileSlice";
import { logout } from './authAPI'
import { ACCOUNT_TYPE } from "../../utils/constants";

const {
    ADD_APPLICANT_EDUCATION,
    EDIT_APPLICANT_EDUCATION,
    DELETE_APPLICANT_EDUCATION,
    ADD_APPLICANT_CERTIFICATION,
    EDIT_APPLICANT_CERTIFICATION,
    DELETE_APPLICANT_CERTIFICATION,
    GET_APPLICANT_PUBLIC_PROFILE,
    GET_RECRUITER_PUBLIC_PROFILE,
    UPDATE_APPLICANT_PROFILE_DETAILS,
    UPDATE_RECRUITER_PROFILE_DETAILS
} = settingsEndpoints;

export function updateDisplayPicture(token , formData){
    return async(dispatch)=>{
        console.log(formData)
        const toastId = toast.loading("Loading...");
        try{

            const response = await apiConnector(
                "PUT",
                settingsEndpoints.UPDATE_USER_DISPLAY_PICTURE,
                formData,
                {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`
                }
            );

            if(!response.data.success)
                throw new Error(response.data.message);

            console.log("UPDATE DISPLAY PICTURE API....",response);
            dispatch(setUser(response.data.data));
            toast.success("Display Picture Updated Successfully");
            
        }catch(err){
            console.log("UPDATE_DISPLAY_PICTURE_API RESPONSE....",err);
            toast.err("Could Not Update Display Picture");
        }
        finally{
            toast.dismiss(toastId);
        }
    }
}

export async function fetchApplicantPublicProfile(token,applicantId){
    let result = null;
    const toastId = toast.loading("loading");
    try{

        const response = await apiConnector(
            "POST",
            GET_APPLICANT_PUBLIC_PROFILE,
            {applicantId},
            {
                Authorization: `Bearer ${token}`
            }
        );
        
        if(!response?.data?.success)
            throw new Error(response?.data?.message);

        // toast.success(response?.data?.message);
        // console.log("GET APPLICANT PUBLIC PROFILE API RESPONSE....",response);
        result = response?.data?.data;

    }catch(err){
        // console.log("GET APPLICANT PUBLIC PROFILE API ERROR....",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    toast.dismiss(toastId);
    return result; 
}

export async function fetchRecruiterPublicProfile(token,recruiterId){
    let result = null;
    const toastId = toast.loading("loading");
    try{

        const response = await apiConnector(
            "POST",
            GET_RECRUITER_PUBLIC_PROFILE,
            {recruiterId},
            {
                Authorization: `Bearer ${token}`
            }
        );
        
        if(!response?.data?.success)
            throw new Error(response?.data?.message);

        // toast.success(response?.data?.message);
        // console.log("GET RECRUITER PUBLIC PROFILE API RESPONSE....",response);
        result = response?.data?.data;

    }catch(err){
        // console.log("GET RECRUITER PUBLIC PROFILE API ERROR....",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    toast.dismiss(toastId);
    return result;
}

export function addEducationDetails(token,data){
    return async(dispatch)=>{
        const toastId = toast.loading("Updating");
        try{
            const response = await apiConnector(
                "POST",
                ADD_APPLICANT_EDUCATION,
                data,
                {
                    Authorization: `Bearer ${token}`
                }
            );
            
            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            toast.dismiss(toastId);
            // console.log("ADD APPLICANT EDUCATON API RESPONSE....",response);
            
            dispatch(setUser(response?.data?.updatedUser));
            toast.success(response?.data?.message);
            
        }catch(err){
            // console.log("ADD APPLICANT EDUCATON API ERROR....",err);
            toast.dismiss(toastId);
            toast.error(err?.response?.data?.message || err?.message);
        }
    }
}

export async function editEducationDetails(token,data){
    let result = null;
    const toastId = toast.loading("Updating");
    try{
        const response = await apiConnector(
            "PUT",
            EDIT_APPLICANT_EDUCATION,
            data,
            {
                Authorization: `Bearer ${token}`
            }
        );
        
        if(!response?.data?.success)
            throw new Error(response?.data?.message);
        
        
        // console.log("EDIT APPLICANT EDUCATON API RESPONSE....",response);
        result = response?.data?.updatedEducation;
        toast.dismiss(toastId);
        toast.success(response?.data?.message);
        
    }catch(err){
        // console.log("EDIT APPLICANT EDUCATON API ERROR....",err);
        toast.dismiss(toastId);
        toast.error(err?.response?.data?.message || err?.message);
    }
    return result;
}

export function deleteEducationDetails(token,educationId){
    return async(dispatch)=>{
        const toastId = toast.loading("Updating");
        try{
            const response = await apiConnector(
                "PUT",
                DELETE_APPLICANT_EDUCATION,
                {educationId},
                {
                    Authorization: `Bearer ${token}`
                }
            );
            
            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            toast.dismiss(toastId);
            // console.log("DELETE APPLICANT EDUCATON API RESPONSE....",response);
            
            dispatch(setUser(response?.data?.updatedUser));
            toast.success(response?.data?.message);
            
        }catch(err){
            // console.log("DELETE APPLICANT EDUCATON API ERROR....",err);
            toast.dismiss(toastId);
            toast.error(err?.response?.data?.message || err?.message);
        }
    }
}

export function addCertificationDetails(token,data){
    return async(dispatch)=>{
        const toastId = toast.loading("Updating");
        try{
            const response = await apiConnector(
                "POST",
                ADD_APPLICANT_CERTIFICATION,
                data,
                {
                    Authorization: `Bearer ${token}`
                }
            );
            
            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            toast.dismiss(toastId);
            // console.log("ADD APPLICANT CERTIFICATION API RESPONSE....",response);
            
            dispatch(setUser(response?.data?.updatedUser));
            toast.success(response?.data?.message);
            
        }catch(err){
            // console.log("ADD APPLICANT CERTIFICATION API ERROR....",err);
            toast.dismiss(toastId);
            toast.error(err?.response?.data?.message || err?.message);
        }
    }
}

export async function editCertificationDetails(token,data){
    let result = null;
    const toastId = toast.loading("Updating");
    try{
        const response = await apiConnector(
            "PUT",
            EDIT_APPLICANT_CERTIFICATION,
            data,
            {
                Authorization: `Bearer ${token}`
            }
        );
        
        if(!response?.data?.success)
            throw new Error(response?.data?.message);
        
        
        // console.log("EDIT APPLICANT CERTIFICATION API RESPONSE....",response);
        result = response?.data?.updatedCertification;
        toast.dismiss(toastId);
        toast.success(response?.data?.message);
        
    }catch(err){
        // console.log("EDIT APPLICANT CERTIFICATION API ERROR....",err);
        toast.dismiss(toastId);
        toast.error(err?.response?.data?.message || err?.message);
    }
    return result;
}

export function deleteCertificationDetails(token,certificationId){
    return async(dispatch)=>{
        const toastId = toast.loading("Updating");
        try{
            const response = await apiConnector(
                "PUT",
                DELETE_APPLICANT_CERTIFICATION,
                {certificationId},
                {
                    Authorization: `Bearer ${token}`
                }
            );
            
            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            toast.dismiss(toastId);
            // console.log("DELETE APPLICANT CERTIFICATION API RESPONSE....",response);
            
            dispatch(setUser(response?.data?.updatedUser));
            toast.success(response?.data?.message);
            
        }catch(err){
            // console.log("DELETE APPLICANT CERTIFICATION API ERROR....",err);
            toast.dismiss(toastId);
            toast.error(err?.response?.data?.message || err?.message);
        }
    }
}

export function updateProfile (token,data,accountType){
    return async(dispatch)=>{
        const toastId = toast.loading("Updating");
        try{

            const response = await apiConnector(
                "PUT",
                accountType === ACCOUNT_TYPE.APPLICANT
                ? UPDATE_APPLICANT_PROFILE_DETAILS : UPDATE_RECRUITER_PROFILE_DETAILS,
                data,
                {
                    Authorization: `Bearer ${token}`
                }
            );
            
            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            toast.dismiss(toastId);
            // console.log("UPDATE USER PROFILE API RESPONSE....",response);

            const userName = response?.data?.updatedUserDetails?.name || response?.data?.updatedUserDetails?.companyName || response?.data?.updatedUserDetails?.firstName + " " + response?.data?.updatedUserDetails?.secondName
            
            let userImage = response?.data?.user?.image ||
            `https://api.dicebear.com/5.x/initials/svg?seed=${userName}`;

            let user = {
                ...response?.data?.updatedUserDetails,
                image: userImage
            }

            dispatch(setUser(user));
            toast.success("Profile Updated Successfully");
            
        }catch(err){
            // console.log("UPDATE USER PROFILE API ERROR....",err);
            toast.dismiss(toastId);
            toast.error(err?.response?.data?.message || err?.message);
        }
    }
}

export async function changePassword(token,data){
 
    const toastId = toast.loading("updating...");
    try{
        const response = await apiConnector(
            "POST",
            settingsEndpoints.UPDATE_USER_PASSWORD,
            data,
            {
                Authorization: `Bearer ${token}`
            }
        );

        if(!response?.data?.success)
            throw new Error(response?.data?.message);

        // console.log("UPDATE PASSWORD API RESPONSE....",response);
        toast.success(response?.data?.message);

    }catch(err){
        // console.log("UPDATE PASSWORD API RESPONSE....",err);
        toast.error(err?.response?.data?.message || err?.message);
    }
    toast.dismiss(toastId);
}

export function deleteAccount(token,navigate){
    return async(dispatch)=>{
        const toastId = toast.loading("deleting");
        try{

            const response = await apiConnector(
                "DELETE",
                settingsEndpoints.DELETE_USER_PROFILE,
                null,
                {
                    Authorization: `Bearer ${token}`
                }
            );

            if(!response?.data?.success)
                throw new Error(response?.data?.message);
            
            // console.log("DELETE PROFILE API RESPONSE....",response);
            toast.success(response?.data?.message);
            
            dispatch(logout(navigate));
            
        }catch(err){
            // console.log("DELETE_PROFILE_API API ERROR............", err);
            toast.error(err?.response?.data?.message || err?.message);
        }
        toast.dismiss(toastId);
    }
}