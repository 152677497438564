
export const validateDOB = (date)=>{
    const dob = new Date(date);
    const currDate = new Date();
    const yearDiff = currDate.getFullYear() - dob.getFullYear();
    const monthDiff = currDate.getMonth() - dob.getMonth();
    const dayDiff = dob.getDay() - currDate.getDay();
    
    if(yearDiff < 18)
        return false;
    
    if(yearDiff == 0 && monthDiff < 0)
        return false;

    if(monthDiff == 0 && dayDiff < 0)
        return false
    
    return true;
}
export const isValidURL = (url)=> 
{
    let regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    let res = 
    url.match(regex);
    return (res !== null);
}