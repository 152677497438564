import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    walkIn:null,
    editWalkIn:false,
    walkInLoading:false
}

const walkInDriveSlice = createSlice({
    name:"walkInDrive",
    initialState,
    reducers:{
        setWalkIn: (state , action)=>{
            state.walkIn = action.payload;
        },
        setEditWalkIn: (state , action)=>{
            state.editWalkIn = action.payload;
        },
        setWalkInLoading:(state,action)=>{
            state.walkInLoading = action.payload;
        },
        resetWalkInState: (state)=>{
            state.job = null;
            state.editJob = false;
        }
    }
});

export const { 
    setWalkIn,
    setEditWalkIn,
    setWalkInLoading,
    resetWalkInState 
} = walkInDriveSlice.actions;

export default walkInDriveSlice.reducer;