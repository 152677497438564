import React, { useEffect, useState } from 'react';
import { ACCOUNT_TYPE, QUALIFICATION } from '../../../../utils/constants'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCertificationDetails, editCertificationDetails, deleteCertificationDetails } from '../../../../services/operations/SettingsAPI';
import { setUser } from '../../../../slices/profileSlice';
import ChipInput from '../../../common/ChipInput';

const CertificationForm = ({data}) => {

  const dispatch = useDispatch();
  const {user} = useSelector(state => state.profile);
  const {token} = useSelector(state => state.auth);
  const [loading,setLoading] = useState(false);
  const [startDate,setstartDate] = useState(data?.startDate);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: {errors,isSubmitSuccessful}
  } = useForm();

  useEffect(()=>{
    // console.log("data",getValues());
    if(!data && isSubmitSuccessful){
      reset({
        name:"",
        skills:[],
        media:"",
        startDate:"",
        endDate:"",
        institute:""
      });
      
    }
    else if(data && isSubmitSuccessful){
      reset({
        name: data?.name,
        skills: data?.skills,
        media: data?.media,
        startDate: data?.startDate,
        endDate: data?.endDate,
        institute: data?.institute
      });
    }
  },[isSubmitSuccessful])

  const addNewCertification = (formdata)=>{
    setLoading(true);
    formdata = {
      ...formdata,
      skills: JSON.stringify(formdata?.skills)
    };
    // console.log(formdata)
    dispatch(addCertificationDetails(token,formdata))

    setLoading(false);
  }

  const editCertification = async(formdata)=>{
    setLoading(true);
    // console.log(formdata)
    try {
      formdata = {
        ...formdata,
        certificationId:data?._id,
        skills: JSON.stringify(formdata?.skills)
      };
      // const response = null;
      const response = await editCertificationDetails(token,formdata);
      
      if(response){
        let updatedCertificationArr = user?.certification.map(ct => ct);
        let certificationIndex = updatedCertificationArr?.findIndex(ct => ct?._id === formdata?.certificationId);
        updatedCertificationArr[certificationIndex] = response;
  
          // console.log(updatedEducationArr)
          let updatedUser = {
              ...user,certification: updatedCertificationArr
          }
  
          // console.log(updatedUser)
          dispatch(setUser(updatedUser));
      }
      setLoading(false);

    } catch (err) {
      
      setLoading(false);
    }
  }

  const deleteCertification = (certificationId)=>{
    setLoading(true);
    // console.log(formdata)
    dispatch(deleteCertificationDetails(token,certificationId))

    setLoading(false);
  }

  return (
    <form className='my-6 flex flex-col justify-between gap-6 bg-richblack-800 p-6 sm:px-10 px-6  rounded-md border border-richblack-700'
      onSubmit={data ? handleSubmit(editCertification) : handleSubmit(addNewCertification)}
    >
      {/* Name of Certification */}
      <div className='flex justify-between items-center gap-x-6'>
        <p className='text-[18px] font-bold'>{data ? `${data?.name}` : "Add New"}</p>
        { data && <button type='button' onClick={()=> deleteCertification(data?._id)} >Delete</button>}
      </div>

      {/* Certificate Name */}
      <label className='flex flex-col w-full'>
        <p className='text-[16px]'>Certification Name</p>
        <input
            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
            type='text'
            name='name'
            placeholder='Enter Certification Name'
            {...register("name",{
              required:{
                value: true,
                message: "Required"
              },
            })}
            defaultValue={data?.name}
        />
          {
            errors.name &&
            <span className="text-[12px] text-yellow-100">
                {errors.name.message}
            </span>
          }
      </label>
        
      {/*Institute*/}
      <label className='flex flex-col w-full'>
        <p className='text-[16px]'>Institute</p>
        <input
            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
            type='text'
            name='institute'
            placeholder='Enter Institute'
            {...register("institute",{
              required:{
                value: true,
                message: "Required"
              },
            })}
            defaultValue={data?.institute}
        />
          {
            errors.institute &&
            <span className="text-[12px] text-yellow-100">
                {errors.institute.message}
            </span>
          }
      </label>

      {/* Skills */}
      <ChipInput
        label={<p>Skills</p>}
        edit={data ? true : false}
        editData={data?.skills ? data?.skills : []}
        name={"skills"}
        type={"text"}
        placeholder={"Enter Skill and press Enter"}
        register={register}
        errors={errors}
        setValue={setValue}
        require={"Skills"}
        isSubmitSuccessful={isSubmitSuccessful}
      />
        
      {/*Media Link */}
      <label className='flex flex-col w-full'>
        <p className='text-[16px]'>Certificate Link</p>
        <input
            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
            type='text'
            name='media'
            placeholder='Enter Certificate Link'
            {...register("media",{
              required:{
                value: true,
                message: "Required"
              },
            })}
            defaultValue={data?.media}
        />
          {
            errors.media &&
            <span className="text-[12px] text-yellow-100">
                {errors.media.message}
            </span>
          }
      </label>
        
      {/* Dates */}
      <div className='flex max-sm:flex-col gap-6'>
        {/* Start Date */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>Start Date</p>
          <input
              className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
              type='date'
              name='startDate'
              onInput={(e)=>setstartDate(e.target.value)}
              {...register("startDate", {
                  required:{
                    value: true,
                    message: "Required"
                  },
                  max: {
                      value: new Date().toISOString().split("T")[0],
                      message: "Start Date cannot be in the future.",
                  },
              })}
              defaultValue={data?.startDate}
            />
            {errors.startDate && (
                <span className="text-[12px] text-yellow-100">
                    {errors.startDate.message}
                </span>
            )}
        </label>
        
        {/* End Date */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>End Date</p>
          <input
            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'

            type='date'
            name='endDate'
            {...register("endDate", {
                required:{
                  value: true,
                  message: "Required"
                },
                validate: (val)=>{
                  // console.log(new Date(val).toDateString(),startDate)
                  if(new Date(val) < new Date(startDate))
                    return "End Date cannot be smaller than start date."
                  if(new Date(val) > new Date())
                    return "End Date cannot be in the future.";
                  return true
                },
                max: {
                    value: new Date().toISOString().split("T")[0],
                    message: "End Date cannot be in the future.",
                },
            })}
            defaultValue={data?.endDate}
          />
          {errors.endDate && (
              <span className="text-[12px] text-yellow-100">
                  {errors.endDate.message}
              </span>
          )}
        </label>
      </div>

      {/* Form Buttons */}
      <div className='flex items-center gap-2 self-end'>
        {/* Cancel */}
        <Link to={`/dashboard/${
            user?.accountType === ACCOUNT_TYPE.APPLICANT ? "applicant" 
            : (user?.accountType === ACCOUNT_TYPE.RECRUITER ? "recruiter" : "admin") 
        }/profile`}>
            <button 
                type='reset'
                className='p-2 px-5 bg-richblack-700 text-richblack-5 font-bold rounded-md'
                disabled={loading}>
                Cancel
            </button>
        </Link>
        {/* Submit */}
        <button 
            className='p-2 px-5 bg-yellow-50 text-richblack-900 font-bold rounded-md'
            type='submit'
            disabled={loading}>
            {
              data ? "Save" : "Add"
            }
        </button>
      </div>
    </form>
  )
}

export default CertificationForm;