import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../common/Spinner';
import { AddWalkInDetails } from './AddWalkInDetails';
import { setEditWalkIn, setWalkIn } from '../../../../slices/walkInDriveSlice';
import { fetchWalkInDriveDetails } from '../../../../services/operations/JobAPI';

export const EditWalkInDetails = () => {

    const dispatch = useDispatch();

    const {walkInId} = useParams();
    const {walkIn} = useSelector(state => state.walkInDrive);
    const [loading,setLoading] = useState(false);

    const setWalkInData = async(walkInId)=>{
        setLoading(true);
        const result = await fetchWalkInDriveDetails(walkInId);
        if(result){
            dispatch(setEditWalkIn(true));
            dispatch(setWalkIn(result));
        }
        setLoading(false);
    }
    // console.log("result: ",course);

    useEffect(()=>{

        setWalkInData(walkInId);

    },[]);

    if(loading)
        return <Spinner/>

  return (
    <div className='w-11/12 mx-auto text-richblack-5 font-inter'>
        
        {
            !loading && walkIn &&
            <AddWalkInDetails/>
        }
    </div>
  )
}
