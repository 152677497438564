import React, { useEffect, useState } from 'react';
import { ACCOUNT_TYPE, QUALIFICATION } from '../../../../utils/constants'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addEducationDetails, deleteEducationDetails, editEducationDetails } from '../../../../services/operations/SettingsAPI';
import { setUser } from '../../../../slices/profileSlice';

const EducationForm = ({data}) => {

  const dispatch = useDispatch();
  const {user} = useSelector(state => state.profile);
  const {token} = useSelector(state => state.auth);
  const [loading,setLoading] = useState(false);
  const [startDate,setstartDate] = useState(data?.startDate);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: {errors,isSubmitSuccessful}
  } = useForm();

  useEffect(()=>{
    // console.log("data",getValues());
    if(!data && isSubmitSuccessful){
      reset({
        qualification:"",
        course:"",
        specialization:"",
        startDate:"",
        endDate:"",
        institute:""
      })
    }
    else if(data && isSubmitSuccessful){
      reset({
        qualification: data?.qualification,
        course: data?.course,
        specialization: data?.specializatione,
        startDate: data?.startDate,
        endDate: data?.endDate,
        institute: data?.institute
      })
    }
  },[isSubmitSuccessful])

  const addNewEducation = (formdata)=>{
    setLoading(true);
    // console.log(formdata)
    dispatch(addEducationDetails(token,formdata))

    setLoading(false);
  }

  const editEducation = async(formdata)=>{
    setLoading(true);
    try {
      formdata = {...formdata,educationId:data?._id};
      const response = await editEducationDetails(token,formdata);
      
      let updatedEducationArr = user?.education.map(ed => ed);
      let educationIndex = updatedEducationArr?.findIndex(ed => ed?._id === formdata?.educationId);
        updatedEducationArr[educationIndex] = response;

        // console.log(updatedEducationArr)
        let updatedUser = {
            ...user,education: updatedEducationArr
        }

        // console.log(updatedUser)
        dispatch(setUser(updatedUser));
      setLoading(false);

    } catch (err) {
      
      setLoading(false);
    }
  }

  const deleteEducation = (educationId)=>{
    setLoading(true);
    console.log(educationId)
    dispatch(deleteEducationDetails(token,educationId))

    setLoading(false);
  }

  return (
    <form className='my-6 flex flex-col justify-between gap-6 bg-richblack-800 p-6 sm:px-10 px-6  rounded-md border border-richblack-700'
      onSubmit={data ? handleSubmit(editEducation) : handleSubmit(addNewEducation)}
    >
      {/* Name of Course */}
      <div className='flex justify-between items-center gap-x-6'>
        <p className='text-[18px] font-bold'>{data ? `${data?.course}` : "Add New"}</p>
        { data && <button type='button' onClick={()=> deleteEducation(data?._id)} >Delete</button>}
      </div>

      <div className='flex max-sm:flex-col gap-6'>
        {/* Qualication */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>Qualification</p>
          <select
            className="outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]"
            name="qualification"
            size={1}
            defaultValue={data?.qualification}
            {...register("qualification",{
              required:{
                value: true,
                message: "Required"
              },
            })}
            >
              {
                  Object.values(QUALIFICATION).map((element,index)=>{
                      return <option key={index} value={element}>
                              {element}
                          </option>
                  })
              }
          </select>
        </label>
          
        {/*Institute*/}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>Institute</p>
          <input
              className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
              type='text'
              name='institute'
              placeholder='Enter Institute'
              {...register("institute",{
                required:{
                  value: true,
                  message: "Required"
                },
              })}
              defaultValue={data?.institute}
          />
            {
              errors.institute &&
              <span className="text-[12px] text-yellow-100">
                  {errors.institute.message}
              </span>
            }
        </label>
      </div>

      <div className='flex max-sm:flex-col gap-6'>
        {/* Course */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>Course</p>
          <input
              className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
              type='text'
              name='course'
              placeholder='Enter Course'
              {...register("course",{
                required:{
                  value: true,
                  message: "Required"
                },
              })}
              defaultValue={data?.course}
          />
            {
              errors.course &&
              <span className="text-[12px] text-yellow-100">
                  {errors.course.message}
              </span>
            }
        </label>
        
        {/*Specialization */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>Specialization</p>
          <input
              className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
              type='text'
              name='specialization'
              placeholder='Enter Specialization'
              {...register("specialization",{
                required:{
                  value: true,
                  message: "Required"
                },
              })}
              defaultValue={data?.specialization}
          />
            {
              errors.specialization &&
              <span className="text-[12px] text-yellow-100">
                  {errors.specialization.message}
              </span>
            }
        </label>
      </div>
          
      <div className='flex max-sm:flex-col gap-6'>
        {/* Start Date */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>Start Date</p>
          <input
              className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'
              type='date'
              name='startDate'
              onInput={(e)=>setstartDate(e.target.value)}
              {...register("startDate", {
                  required:{
                    value: true,
                    message: "Required"
                  },
                  max: {
                      value: new Date().toISOString().split("T")[0],
                      message: "Start Date cannot be in the future.",
                  },
              })}
              defaultValue={data?.startDate}
            />
            {errors.startDate && (
                <span className="text-[12px] text-yellow-100">
                    {errors.startDate.message}
                </span>
            )}
        </label>
        
        {/* End Date */}
        <label className='flex flex-col w-full'>
          <p className='text-[16px]'>End Date</p>
          <input
            className='outline-none mt-2 text-richblack-200 w-full p-2 py-2 bg-richblack-700 rounded-lg text-[16px] shadow-[0px_0.8px_0px_0px_#585D69]'

            type='date'
            name='endDate'
            {...register("endDate", {
                required:{
                  value: true,
                  message: "Required"
                },
                validate: (val)=>{
                  // console.log(new Date(val).toDateString(),startDate)
                  if(new Date(val) < new Date(startDate))
                    return "End Date cannot be smaller than start date."
                  return true
                },
            })}
            defaultValue={data?.endDate}
          />
          {errors.endDate && (
              <span className="text-[12px] text-yellow-100">
                  {errors.endDate.message}
              </span>
          )}
        </label>
      </div>

      {/* Form Buttons */}
      <div className='flex items-center gap-2 self-end'>
        {/* Cancel */}
        <Link to={`/dashboard/${
            user?.accountType === ACCOUNT_TYPE.APPLICANT ? "applicant" 
            : (user?.accountType === ACCOUNT_TYPE.RECRUITER ? "recruiter" : "admin") 
        }/profile`}>
            <button 
                type='reset'
                className='p-2 px-5 bg-richblack-700 text-richblack-5 font-bold rounded-md'
                disabled={loading}>
                Cancel
            </button>
        </Link>
        {/* Submit */}
        <button 
            className='p-2 px-5 bg-yellow-50 text-richblack-900 font-bold rounded-md'
            type='submit'
            disabled={loading}>
            {
              data ? "Save" : "Add"
            }
        </button>
      </div>
    </form>
  )
}

export default EducationForm;