import React from 'react'
import { formattedDate } from '../../../../utils/dateFormatter'

const Certificates = ({certificate}) => {
  return (
    <div className='flex flex-col  text-[15px]'>
        {/* <label className='flex flex-col gap-2 xxxs:w-[30%]'>
            <p className='text-sm text-richblack-600'>First Name</p>
            
            </label> */}

        <div className=' w-full flex max-sm:flex-col  sm:items-center justify-between'>
            <p className='text-[17px] font-bold'>{`${certificate?.name} -`}</p>
            <p className='text-md text-richblack-25'>
                {`${formattedDate(certificate?.startDate)} - ${formattedDate(certificate?.endDate)}`}
            </p>
        </div>
        <p className='text-richblack-50'>{certificate?.institute}</p>
        
        <div className='flex flex-wrap items-center gap-2'>
            <p>Skills -</p>
            {
                certificate?.skills?.map((sk,index) => <span key={index}>{sk}</span>)
            }
        </div>
        <a href={certificate?.media} className='w-fit text-sm text-yellow-300 font-bold'>View Certificate</a>
    </div>
   
  )
}

export default Certificates