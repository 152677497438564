import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteWalkInDriveByRecruiter, fetchCaJobsByRecruiter, fetchWalkInDrivesByRecruiter } from '../../../../services/operations/JobAPI';
import { Job_card } from '../../Catalog/Job_card';
import Spinner from '../../../common/Spinner';
import { formattedFullDate } from '../../../../utils/dateFormatter';
import { Link } from 'react-router-dom';

const MyWalkInDrives = () => {
    
    const {token} = useSelector(state => state.auth);
    const [walkInDrives,setWalkInDrives] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        const fetchWalkInDrives = async()=>{
            setLoading(true);
            const result = await fetchWalkInDrivesByRecruiter(token);
            if(result){
                setWalkInDrives(result);
            }
            setLoading(false);
        }
        fetchWalkInDrives();
    },[])

    const deleteWalkInDrive = async(walkInId)=>{
        const result = await deleteWalkInDriveByRecruiter({walkInId},token);
        setWalkInDrives(prev => prev?.filter(wd => wd?._id !== walkInId));
    }

    if(loading || !walkInDrives)
        return <Spinner/>
    
    if(!loading && !walkInDrives)
        return <p>500 Internal Server Error</p>

    return (
    <div className='w-11/12 max-w-maxContent mx-auto'>
        <p className='p-2 my-4 text-4xl font-semibold'>My Walk In Drives</p>
            {
                walkInDrives && walkInDrives.length 
                
                ? <section className="flex flex-col items-stretch my-10 ">
                    {/* List Header */}
                    <div className="hidden w-full h-[30px] xslg:grid grid-cols-[2fr_2fr_2fr_2fr] place-content-center  gap-6 p-3 py-6 border border-richblack-700 text-yellow-50">
                        <p className="text-start px-2">Role</p>
                        <p className="px-2">Scheduled On</p>
                        <p className="px-2">Register Until</p>
                        <p className="px-2 place-self-center">Actions</p>
                    </div>

                    {/* List Items */}
                    {walkInDrives.map((wd, index) => {
                        return (
                            <div
                                key={index}
                                className="w-full grid xslg:grid-cols-[2fr_2fr_2fr_2fr]  gap-4 p-3 border border-richblack-700 "
                            >
                                <div className="w-full my-auto text-richblack-50 px-2 font-bold flex justify-center gap-x-4 flex-wrap">
                                    {
                                        wd?.roles?.map((role, index) => (
                                            <p key={index}>{`${role}`}</p>
                                        ))
                                    }
                                </div>
                                <div className="w-full my-auto text-richblack-100 px-2">
                                    <p>{formattedFullDate(wd?.scheduleDay)}</p>
                                </div>
                                <div className="w-full my-auto text-richblack-100 px-2">
                                    <p>{formattedFullDate(wd?.deadline)}</p>
                                </div>

                                <div className="flex gap-x-2 items-center text-xs text-richblack-50 font-bold md:justify-evenly p-2">
                                    <Link
                                        to={`/dashboard/recruiter/edit-walk-in/${wd?._id}`} 
                                        className="bg-caribbeangreen-300 hover:bg-caribbeangreen-400 transition-all duration-100 px-4 py-2 rounded-lg flex-grow text-center"
                                    >
                                        <button>Edit</button>
                                    </Link>
                                    
                                    <button 
                                        className="bg-pink-600 hover:bg-pink-700 transition-all duration-100 px-4 py-2 rounded-lg flex-grow"
                                        onClick={()=>{deleteWalkInDrive(wd?._id)}}
                                    >   
                                        Delete
                                    </button>
                                    
                                </div>
                            </div>
                        );
                    })}
                </section>
                
                : <p>No Walk In Drives Posted Yet</p>
            }
    </div>
  )
}

export default MyWalkInDrives